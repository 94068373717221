.pc
    &__seqj
        position: relative        

        .heading-abs
            position: absolute
            top: var(--space-lg)
            width: 100%
            left: 50%
            -webkit-transform: translateX(-50%)
            transform: translateX(-50%)
            h3
                font-weight: 600
                text-align: center
                font-size: calc(2.5vw * 3)
                @include breakpoint(md)
                    font-size: 2.5vw

.slideshow
    position: relative
    width: 100%
    max-width: 1800px
    margin-left: auto
    margin-right: auto
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    
    &__container
        margin: 0 auto
        width: 100%
        height: 350px
        position: relative
        perspective: 1000px        

        @include breakpoint(sm)
            width: 450px
            height: 450px

        @include breakpoint(md)
            width: 18vw
            height: 18vw
            perspective: 850px

    &__content
        height: 100%
        width: 100%
        position: absolute
        transform-style: preserve-3d
        transition: transform 1s

    &__item-content
        display: block
        position: absolute
        width: 100%
        text-align: center
        opacity: 0.5
        pointer-events: none
        transition: transform 1s, opacity 1s
        @include breakpoint(sm)
            width: 450px
        @include breakpoint(md)
            width: 18vw

        &.active-slide
            opacity: 1
            pointer-events: auto
        &.back-slide
            opacity: 0

    &__item-image
        padding: var(--space-xs)    
        user-select: none
        cursor: pointer

        img
            user-select: none
            -webkit-user-select: none
            -moz-user-focus: none
            -ms-user-select: none

        &:hover,
        &:focus-visible
            .callToaction
                background-color: var(--color-accent)
                color: #fff
                border-color: var(--color-accent)

        .callToaction
            margin-top: var(--space-sm)
            background-color: transparent
            color: #A9A7A5
            border-width: 2px
            line-height: 1em
            border-style: solid
            border-color: #A9A7A5
            transition: box-shadow 0.2s, background-color 0.3s, color 0.3s border-color 0.3s
            font-size: 4vw
            @include breakpoint(sm)
                font-size: 2.5vw
            @include breakpoint(md)
                font-size: .8vw

    &__item
        &.slideshow__item-0
            transform: rotateY(0deg) translateZ(250px)

            @include breakpoint(sm)
                transform: rotateY(0deg) translateZ(350px)
            
            @include breakpoint(lg)
                transform: rotateY(0deg) translateZ(18vw)

        &.slideshow__item-1
            transform: rotateY(90deg) translateZ(250px) rotateY(-90deg)
            @include breakpoint(sm)
                transform: rotateY(90deg) translateZ(350px) rotateY(-90deg)
            @include breakpoint(md)
                transform: rotateY(90deg) translateZ(18vw) rotateY(-90deg)

        &.slideshow__item-2
            transform: rotateY(180deg) translateZ(250px) rotateY(-180deg)
            @include breakpoint(sm)
                transform: rotateY(180deg) translateZ(350px) rotateY(-180deg)
            @include breakpoint(md)
                transform: rotateY(180deg) translateZ(18vw) rotateY(-180deg)
        &.slideshow__item-3
            transform: rotateY(270deg) translateZ(250px) rotateY(-270deg)
            @include breakpoint(sm)
                transform: rotateY(270deg) translateZ(350px) rotateY(-270deg)
            @include breakpoint(md)
                transform: rotateY(270deg) translateZ(18vw) rotateY(-270deg)

    &__control,
    &__control-modal
        position: absolute
        z-index: 4
        top: 50%
        -webkit-transform: translateY(-50%)
        transform: translateY(-50%)
        
        &:first-of-type
            left: var(--space-sm)

            @include breakpoint(md)
                left: var(--space-lg)
            
            .slideshow__btn .icon
                margin-right: 0.1em
                -webkit-transform: rotate(180deg)
                transform: rotate(180deg)

        &:last-of-type
            right: var(--space-sm)
            @include breakpoint(md)
                right: var(--space-lg)

            .slideshow__btn .icon
                margin-left: 0.1em

    &__control-modal
        position: absolute
        z-index: 4
        top: 100%
        -webkit-transform: translateY(-200%)
        transform: translateY(-200%)
        @include breakpoint(sm)
            -webkit-transform: translateY(-110%)
            transform: translateY(-110%)
        @include breakpoint(md)
            top: 50%
            -webkit-transform: translateY(-50%)
            transform: translateY(-50%)

    &__btn
        width: calc(3.5vw * 3.5)
        height: calc(3.5vw * 3.5)
        border-radius: 50%
        background: #A9A7A5
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        transition: background-color 0.3s

        @include breakpoint(md)
            width: 3.5vw
            height: 3.5vw

        &:hover,
        &:focus-visible
            background-color: var(--color-accent)
            color: var(--color-accent)

        .icon
            font-size: calc(1.5vw * 3.5)
            color: #fff

            @include breakpoint(md)
                font-size: 1.5vw


.slideshow__content li
    transform-style: preserve-3d


