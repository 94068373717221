.pc
    &__seqi
        position: relative
        display: flex
        justify-content: center

    &__seqi
        align-items: center
        min-height: auto
        height: 100%
    
    &__seqi-degree
        position: absolute
        top: var(--component-padding)
        left: var(--component-padding)
        display: none
        @include breakpoint(md)
            display: block

        .icon
            width: 2.6vw
            height: 2.6vw
            color: var(--color-dark-grey)
            stroke: var(--color-dark-grey)

    &__seqi-ctrl
        position: absolute
        left: 0
        top: calc(var(--space-sm) + 5vw)
        width: 100%
        padding: var(--space-sm) var(--space-xs)
        display: flex
        justify-content: space-between

        @include breakpoint(md)
            top: auto
            padding: var(--space-sm) var(--component-padding)
            bottom: calc(1vw + var(--space-sm) + 1.5vw)
    
    &__seqi-ctrl-return
        flex-basis: 20%
    
    &__seqi-ctrl-return-btn

        &:hover
            .icon
                transform: scale(1.1)
                
                .svg-fill-grey
                    fill: var(--color-accent)

                .svg-stroke-grey
                    stroke: var(--color-accent)

        &:focus-visible
            .icon
                transform: scale(1.1)
                
                .svg-fill-grey
                    fill: var(--color-accent)

                .svg-stroke-grey
                    stroke: var(--color-accent)

        .icon
            width: calc(1.5vw * 4)
            height: calc(1.5vw * 4)
            color: var(--color-dark-grey)
            stroke: var(--color-dark-grey)
            transform: scale(1.0)
            transition: color 0.2s, stroke 0.2s, transform 0.2s

            @include breakpoint(md)
                width: 1.5vw
                height: 1.5vw
    
    &__seqi-ctrl-texture
        text-align: center
        flex-basis: 80%
        display: flex
        align-items: center
        justify-content: center
        
        @include breakpoint(md)
            flex-basis: 60%
            flex-grow: 0


    &__seqi-ctrl-texture-checkboxes
        line-height: 0


    &__seqi-ctrl-kanne
        color: var(--color-text-grey)
        font-size: calc(1.5vw * 4)
        text-transform: uppercase
        font-weight: 500
        display: inline-block
        text-decoration: none

        @include breakpoint(md)
            font-size: 1.5vw

        &.active-kanne
            color: var(--color-text-black)

        &:hover,
        &:focus-visible
            color: var(--color-accent)
            
                
    &__seqi-ctrl-texture-content
        margin-right: var(--space-md)
    

    &__seqi-ctrl-details
        display: none
        align-items: center
        @include breakpoint(md)
            display: flex
            flex-basis: 20%

        span
            color: var(--color-text-light-grey)
            font-size: 0.8vw
            letter-spacing: 0.05em
            text-transform: uppercase
            display: block
            font-weight: 500

    &__seqi-scroll-container
        display: block
        @include breakpoint(md)
            display: none

    &__seqi-scroll
        position: absolute
        left: 50%
        transform: translateX(-50%)
        -webkit-transform: translateX(-50%)
        
        &:first-of-type
            top: var(--space-sm)
        
        &:last-of-type
            bottom: calc(1vw + var(--space-sm) + 5vw)

    &__seqi-scroll-btn .icon
        height: 5vw
        width: auto


    &__seqi-popup
        position: absolute
        top: 50%
        left: 0
        width: 100%
        max-width: 50%
        z-index: 10
        visibility: hidden
        opacity: 0
        overflow: hidden
        transform: translateY(-50%)
        -webkit-transform: translateY(-50%)

        @include breakpoint(md)
            top: 0
            max-width: 18vw
            transform: translateY(0)
            -webkit-transform: translateY(0)
        

        &:not(.popup--is-visible)
            pointer-events: none
        
        &.popup--is-visible 
            opacity: 1
            visibility: visible

    &__seqi-popup-content
        position: relative
        overflow: hidden
        padding: var(--space-xs)
        background: rgba(#fff, 0.6)


    &__seqi-popup-list-item
        display: flex
        gap: var(--space-xxs)
        align-items: center
        margin-bottom: var(--space-xs)

        @include breakpoint(md)
            gap: var(--space-sm)
        
        // &:first-child
        //     margin-bottom: calc(var(--space-unit)*0.5)
        //     @include breakpoint(md)
        //         margin-bottom: calc(var(--space-unit)*0.1)

        figure
            flex-shrink: 0
            flex-basis: 15%
            display: flex
            align-items: center
            justify-content: center
            // @include breakpoint(md)
            //     width: 3vw
            //     height: 3vw
            
            .icon
                width: calc(2vw * 2.5)
                height: calc(2vw * 2.5)
                @include breakpoint(md)
                    width: 2vw
                    height: 2vw
            // 41 14
            #icon-timer-display
                height: calc(0.8vw * 2.5)
                width: auto
                
                @include breakpoint(md)
                    margin-top: -0.2em
                    height: 0.8vw
            // 39 39
            #icon-schalter
                width: calc(2vw * 2.5)
                height: calc(2vw * 2.5)
                @include breakpoint(md)
                    width: 2vw
                    height: 2vw
            // 22 24
            #icon-tassenzahl
                width: auto
                height: calc(1.4vw * 3)
                @include breakpoint(md)
                    height: 1.4vw
            // 23 22
            #icon-timer
                height: calc(1.6vw * 3)
                width: auto
                @include breakpoint(md)
                    height: 1.6vw
            // 17 24
            #icon-wasser
                width: auto
                height: calc(1.6vw * 3)
                @include breakpoint(md)
                    height: 1.6vw
            // 21 29
            #icon-kalk
                width: auto
                height: calc(1.6vw * 3)
                @include breakpoint(md)
                    height: 1.6vw

    &__seqi-popup-list-item-details
        color: var(--color-dark-grey)
        