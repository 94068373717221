.header
    position: fixed
    left: 0
    bottom: 0
    z-index: 10
    width: 100%
    background-color: var(--header-color-bg)
    height: calc(1vw + var(--space-sm) + 1.5vw)
    transition: background-color 0.3s

    &__content
        position: relative
        display: flex
        justify-content: center
        align-items: center
        height: 100%

        .box
            
            display: inline-block
            margin-right: 0.5em
            height: 10px
            @include breakpoint(md)
                height: 15px
                margin-right: 1em

            svg
                display: block
                height: 100%
                width: auto!important
        
        svg
            height: 100%
            width: auto

        span
            color: var(--header-color)
            text-transform: uppercase
            font-size: 15px
            font-weight: 400
            display: inline-block
            line-height: 1em
            @include breakpoint(md)
                font-size: 20px

        // .icon
        //     height: 15px
        //     width: auto
        //     margin-right: 10px

        .callToaction
            position: absolute
            top: 50%
            right: 0
            display: none
            transform: translate(50%, -50%)
            -webkit-transform: translate(50%, -50%)

            @include breakpoint(md)
                display: block


